import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Login, LoginFormFields } from "../../components/SemanticTheme/Login";
import { getSingleFutureCabinReservation, createLogin } from "@/store/actions";
import { useLoginSelector } from "@/store/selectors";
import { LoginRegisterPage } from "src/graphql/generated-strapi/types";
import { updateAbsoluteLink } from "@components/Utils";

export const LoginComponent: React.FC<
  { content?: LoginRegisterPage } & { showLogoutMessage?: boolean } & {
    overrideContinueCTALabel?: string;
    applyUnderliningStylingCheckoutCTAs?: boolean;
  }
> = (props): JSX.Element => {
  const {
    content,
    showLogoutMessage,
    overrideContinueCTALabel,
    applyUnderliningStylingCheckoutCTAs,
  } = props;
  const dispatch = useDispatch();
  const { hasError, loading, errorMessage, data } = useLoginSelector();
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(Boolean(loading));

  useEffect(() => {
    if (data && data?.customer?.id) {
      dispatch(
        getSingleFutureCabinReservation({
          customerId: data.customer.id,
        }),
      );
    }
    setIsDisabled(Boolean(loading));
  }, [data]);

  return (
    <Login
      title={content?.Title}
      subtitle={content?.Subtitle}
      loginCTALabel={overrideContinueCTALabel || content?.LoginCTALabel}
      loginFailedErrormessage={content?.LoginFailedErrormessage}
      loginEmailFieldLabel={content?.LoginEmailFieldLabel}
      loginEmailFieldHelperText={content?.LoginEmailFieldHelperText}
      loginPasswordFieldHelpText={content?.LoginPasswordFieldHelpText}
      loginPasswordFieldLabel={content?.LoginPasswordFieldLabel}
      emailFieldErrorMessage={content?.EmailFieldErrorMessage}
      loginPasswordValidationFailureLabel={
        content?.LoginPasswordValidationFailureLabel
      }
      loginEmailFieldWatermarkText={content?.LoginEmailFieldWatermarkText}
      passwordFieldWatermarkText={content?.PasswordFieldWatermarkText}
      hasError={hasError}
      disabled={isDisabled}
      requestError={errorMessage || undefined}
      onLoginAttempt={({ email, password, rememberMe }: LoginFormFields) =>
        dispatch(
          createLogin({
            request: { email, password },
            rememberMe: isRememberMeChecked,
          }),
        )
      }
      forgotPasswordCTALabel={content?.LoginForgotPasswordCTALabel}
      forgotPasswordCTARef={updateAbsoluteLink(
        content?.LoginForgotPasswordCTARef?.data?.attributes?.PageRoute,
      )}
      loggedOutMessage={
        showLogoutMessage ? content?.LoggedOutMessage : undefined
      }
      rememberMeChecked={isRememberMeChecked}
      onRememberMeChecked={(isRememberMeChecked) =>
        setIsRememberMeChecked(isRememberMeChecked)
      }
      applyUnderliningStylingCheckoutCTAs={applyUnderliningStylingCheckoutCTAs}
      /* commented code for future use. */
      // rememberMeText={props.content.RememberMeLabel}
      // rememberMeDetailsLabel={props.content.DetailsLabel}
      // rememberMeTandC={<></>}
    />
  );
};
