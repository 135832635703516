import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { pickValuesAsString } from "../../utils/common";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { Typography } from "@mui/material";
import { FHInlineSvg } from "../../components/SemanticTheme/FHInlineSvg";
import { GvStepperTag } from "../../interfaces/giftvouchers";
import { JourneyTypes } from "../../interfaces/stepper";
import { useGVRedirectionObjectByPath } from "../../utils";
import { BasketSummeryOverrideProps } from "./GVAddUpdateTemplate";
import { BookingSummaryContainer } from "../../components/SemanticTheme/BookingSummarySidebar/BookingSummaryContainer";
import {
  ActiveBasketTitle,
  ActiveBasketTotal,
} from "../../components/SemanticTheme/BookingSummarySidebar/ActiveBasket";
import { GiftVoucherBasketItems } from "../../components/SemanticTheme/GiftVoucher/GiftVoucherBasketItems";
import {
  removeGiftVoucher,
  redirectActionLoading,
  setActiveStepperSetting,
  confirmActiveGvStepAction,
} from "@/store/actions";
import {
  useGetGiftVoucherBasketStateSelector,
  useGiftVouchersSavedStateSelector,
  useGvActiveStepSelector,
  useGvFindStepSelector,
  useGvSettingsSelector,
} from "@/store/selectors";
import { filterXSS } from "xss";
import { GiftVoucherBasketSummaryEntity } from "src/graphql/generated-strapi/types";

const useStyles = makeStyles((theme) => ({
  noGiftCoucherAdded: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
    },
  },
}));

const makeGVBookingSummaryCmsData = (content: any) => ({
  ...pickValuesAsString(content, [
    "BasketLabel",
    "BasketNumberOfItemsLabel",
    "GiftVoucherHeadingLabelInBasket",
    "GiftVoucherLabelInBasket",
    "NoGiftVouchersAddedLabel",
    "ProceedButtonLabel",
    "TotalLabel",
    "ChangeButtonLabel",
    "DeleteGiftVoucherLabel",
  ]),
});

export type GVBookingSummaryCmsData = ReturnType<
  typeof makeGVBookingSummaryCmsData
>;

interface SummaryProps extends BasketSummeryOverrideProps {
  attributes?: GiftVoucherBasketSummaryEntity;
  overRideProps?: BasketSummeryOverrideProps;
}

const GVBookingConfirmationComponent: React.FC<SummaryProps> = ({
  attributes,
  overRideProps,
}): JSX.Element => {
  const dispatch = useDispatch();
  const history = useRouter();
  const classes = useStyles();
  const activeStep = useGvActiveStepSelector();
  const { gvSettings } = useGvSettingsSelector();
  const removeIcon = (
    <FHInlineSvg src={gvSettings?.removeImage?.url || ""} size={"20px"} />
  );
  const [disableGVSummary, setDisableGVSummary] = useState(false);
  const cmsData = makeGVBookingSummaryCmsData(
    attributes?.attributes
  ) as GVBookingSummaryCmsData;
  const { data: giftVoucher, loading: isLoading } =
    useGetGiftVoucherBasketStateSelector();
  const { loading: giftVoucherSaving } = useGiftVouchersSavedStateSelector();
  const { nextPageUrl, prevPageUrl, activeStepIndex, formId } =
    useGVRedirectionObjectByPath(history?.asPath);
  const gvCustomizePage = useGvFindStepSelector(GvStepperTag.details);
  const giftVoucherItems =
    giftVoucher && giftVoucher.giftVoucherBasket
      ? giftVoucher.giftVoucherBasket.map((voucher, key) => ({
          price: voucher?.value,
          name: `${cmsData?.GiftVoucherLabelInBasket} ${key + 1}`,
          basketItemId: voucher?.basketItemId || 0,
        }))
      : [];

  const removeGiftVoucherFromBasket = (giftVoucherBasketId: number) => {
    dispatch(removeGiftVoucher(giftVoucherBasketId));
  };

  const proceedButtonLabel =
    activeStep?.tag === GvStepperTag?.select ||
    activeStep?.tag === GvStepperTag?.details
      ? cmsData?.ProceedButtonLabel
      : "";

  const totalPrice =
    giftVoucherItems && giftVoucherItems.length > 0
      ? giftVoucherItems.reduce((total, { price }) => total + price, 0)
      : 0;

  return (
    <BookingSummaryContainer
      primaryCallToActionText={proceedButtonLabel}
      onGvBasketSubmit={overRideProps?.onBasketSubmit as () => void}
      secondaryCallToActionText={
        activeStepIndex > 0 ? cmsData?.ChangeButtonLabel : ""
      }
      isFormSubmissionRequired={activeStepIndex === 1 ? true : false}
      showDrawerOnMobile={false}
      loading={isLoading}
      formId={formId}
      disabled={giftVoucherItems.length < 1 || giftVoucherSaving}
      disableGVSummary={disableGVSummary}
      activeBasketTitle={
        <ActiveBasketTitle
          loading={isLoading}
          title={cmsData?.BasketLabel}
          basketItemAmount={giftVoucherItems.length}
          itemsLabel={cmsData?.BasketNumberOfItemsLabel}
        />
      }
      primaryCallToActionClick={() => {
        dispatch(redirectActionLoading());
        if (activeStepIndex === 0) {
          dispatch(confirmActiveGvStepAction());
          dispatch(
            setActiveStepperSetting({
              activeJourneyStep: activeStepIndex + 1,
              activeJourney: JourneyTypes.gvJourney,
            })
          );
          history.push((nextPageUrl && nextPageUrl.url) || "");
        }
      }}
      secondaryCallToActionClick={() => {
        dispatch(redirectActionLoading());
        if (activeStepIndex >= 1) {
          if (activeStep?.tag === GvStepperTag?.payment) {
            history.push(filterXSS(gvCustomizePage?.url) || "");
          } else history.push((prevPageUrl && prevPageUrl?.url) || "");
        }
      }}
      activeBasketContent={
        giftVoucherItems.length < 1 ? (
          <Typography className={classes.noGiftCoucherAdded}>
            <strong>{cmsData?.NoGiftVouchersAddedLabel}</strong>
          </Typography>
        ) : (
          <GiftVoucherBasketItems
            basketItemsTitle={cmsData?.GiftVoucherHeadingLabelInBasket}
            basketItems={giftVoucherItems}
            removeIcon={removeIcon}
            onRemoveItem={(basketItemId) => {
              removeGiftVoucherFromBasket(basketItemId);
            }}
          />
        )
      }
      activeBasketFooter={
        <ActiveBasketTotal
          loading={isLoading}
          totalLabel={cmsData?.TotalLabel}
          totalPrice={totalPrice}
        />
      }
    />
  );
};

export default GVBookingConfirmationComponent;
