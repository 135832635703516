import React, { PropsWithChildren } from "react";
import FHInputField from "../FHInputField";

export type EmailInputProps = {
  name: string;
  label: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  errorClassName?: string;
  validationFailure: string;
  help?: string;
  errorRef?: any;
  defaultValue?: string;
  control: any;
  rules?: object;
};

export const EmailInput: React.FC<EmailInputProps> = ({
  label,
  name,
  onChange = () => {},
  validationFailure,
  help,
  className,
  errorClassName,
  errorRef = null,
  defaultValue,
  control,
  rules,
}: PropsWithChildren<EmailInputProps>) => {
  return (
    <FHInputField
      name={name}
      label={label}
      control={control}
      rules={rules}
      noOutlineHighlight={true}
      defaultValue={defaultValue}
      errorRef={errorRef}
      validationFailure={validationFailure}
      className={className}
      help={help}
      errorClassName={errorClassName}
      onInputChange={onChange}
    />
  );
};
