import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FHButton } from "../FHButton";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.divider,
    "& p": {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  descriptionContainer: {
    padding: `${theme.spacing(3.5, 4, 1.5)} !important`,
  },
  actionsContainer: {
    padding: theme.spacing(0, 4, 4.5),
  },
  cancelButton: { minWidth: "112px" },
  cancelCTA: { padding: theme.spacing(1, 3) },
  closeButton: {
    color: theme.palette.grey[600],
    borderColor: theme.palette.action.disabled,
    border: "2px solid",
    borderRadius: "10px",
    minWidth: "auto",
    padding: theme.spacing(0.2),
    margin: theme.spacing(0, 0, 0, 1),
    "&:hover": {
      color: theme.palette.grey[600],
      borderColor: theme.palette.action.disabled,
      border: "2px solid",
      borderRadius: "10px",
    },
  },
  noPaddingContainer: {
    padding: `0 !important`,
  },
}));

export type FHSemanticModalProps = {
  open: boolean;
  handleClose: () => void;
  handleConfirm?: () => void;
  title: string;
  description: JSX.Element;
  confirmCTALabel?: string;
  cancelCTALabel?: string;
  closeBtnIcon?: JSX.Element;
  showCancelCTA?: boolean;
  isContentPadding?: boolean;
};

export const FHSemanticModal: React.FC<FHSemanticModalProps> = ({
  open,
  handleClose,
  handleConfirm,
  title,
  description,
  confirmCTALabel = "Confirm",
  cancelCTALabel = "Cancel",
  showCancelCTA = true,
  isContentPadding = true,
  closeBtnIcon = <CloseIcon fontSize="small" />,
}: FHSemanticModalProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="fh-semantic-modal-title"
      aria-describedby="fh-semantic-modal-description"
      data-testid="FHSemanticModalRoot"
      maxWidth={false}
      fullWidth={false}
      PaperProps={{ style: { width: "680px", minHeight: "354px" } }}
    >
      <DialogTitle
        id="fh-semantic-modal-title"
        className={classes.titleContainer}
      >
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography variant="h4">
              <strong data-testid="FHSemanticModalTitle">{title}</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              data-testid="FHSemanticModalCloseButton"
              className={classes.closeButton}
              onClick={handleClose}
            >
              {closeBtnIcon}
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        className={classNames(classes.descriptionContainer, {
          [classes.noPaddingContainer]: !isContentPadding,
        })}
      >
        {description}
      </DialogContent>
      {showCancelCTA || !!handleConfirm ? (
        <DialogActions className={classes.actionsContainer}>
          {showCancelCTA && (
            <Grid item className={classes.cancelButton}>
              <FHButton
                fhSize="md"
                onClick={handleClose}
                fhStyle="secondary"
                data-testid="FHSemanticModalCancelCTA"
                className={classes.cancelCTA}
              >
                {cancelCTALabel}
              </FHButton>
            </Grid>
          )}
          {!!handleConfirm && (
            <FHButton
              onClick={() => {
                handleClose();
                handleConfirm();
              }}
              data-testid="FHSemanticModalConfirmCTA"
            >
              {confirmCTALabel}
            </FHButton>
          )}
        </DialogActions>
      ) : null}
    </Dialog>
  );
};
