import React, { PropsWithChildren } from 'react';
import { FHSelectDropdown } from '../FHSelectDropdown';
import { FHStyle } from '../FHTextField';

export type InputFieldProps = {
  name: string;
  label: string;
  onDropdownChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  validationFailure?: string;
  control?: any;
  rules?: object;
  errorRef?: any;
  defaultValue?: string;
  className?: string;
  errorClassName?: string;
  countries: string[];
  fhStyle?: FHStyle;
};

export const CountryDropdown: React.FC<InputFieldProps> = ({
  label,
  name,
  onDropdownChange = () => {},
  validationFailure,
  control,
  rules,
  errorRef = null,
  defaultValue,
  className,
  errorClassName,
  countries,
  fhStyle,
}: PropsWithChildren<InputFieldProps>) => {
  return (
    <>
      <FHSelectDropdown
        {...{
          name,
          label,
          control,
          rules,
          defaultValue,
          validationFailure,
          className,
          errorClassName,
          errorRef,
          onDropdownChange,
          fhStyle,
        }}
        options={countries.map((country) => ({
          label: country,
          value: country,
        }))}
      />
    </>
  );
};
