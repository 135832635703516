import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { FHSemanticModal } from "../FHSemanticModal";
import FHDrawer from "../FHDrawer";
import classNames from "classnames";

export type ViewAllCabinsModalProps = {
  openModal: boolean;
  onCloseClick?: () => void;
  title?: string;
  isMobile?: boolean;
  isContentPadding?: boolean;
  isMobileAutoHeight?: boolean;
  closeBtnIcon?: JSX.Element;
  showCloseButton?: boolean;
};

const useStyles = makeStyles((theme) => ({
  contentBody: {
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 252px)",
      overflowY: "auto",
    },
  },
  contentBodyAutoHeight: {
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  cabin: {
    padding: theme.spacing(2),
    borderBottom: "1px solid " + theme.palette.divider,
  },
  container: {
    marginTop: theme.spacing(-2),
  },
}));

export const FHModalOrDrawer = ({
  isMobile = false,
  onCloseClick,
  openModal,
  title,
  children,
  isContentPadding,
  isMobileAutoHeight,
  closeBtnIcon,
  showCloseButton = true,
}: PropsWithChildren<ViewAllCabinsModalProps>): JSX.Element => {
  const classes = useStyles(isMobile);

  return isMobile ? (
    <FHDrawer
      modalTitle={title}
      isShowCloseButton={showCloseButton}
      open={openModal}
      closeBtnIcon={closeBtnIcon}
      onClose={onCloseClick}
    >
      <div
        className={classNames(classes.contentBody, {
          [classes.contentBodyAutoHeight]: isMobileAutoHeight,
        })}
      >
        {children}
      </div>
    </FHDrawer>
  ) : (
    <FHSemanticModal
      title={title || ""}
      handleClose={onCloseClick}
      description={<>{children}</>}
      open={openModal}
      showCancelCTA={false}
      isContentPadding={isContentPadding}
      closeBtnIcon={closeBtnIcon}
    />
  );
};
