import React, { PropsWithChildren, useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FHInputField from "../FHInputField";

export type PasswordInputProps = {
  name: string;
  label: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validationFailure?: string;
  help?: string;
  inputReference?: any;
  errorRef?: any;
  defaultValue?: string;
  className?: string;
  errorClassName?: string;
  control: any;
  rules: object;
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  label,
  name,
  validationFailure,
  help,
  errorRef = null,
  defaultValue,
  className,
  errorClassName,
  control,
  rules,
}: PropsWithChildren<PasswordInputProps>) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <FHInputField
      name={name}
      label={label}
      rules={rules}
      noOutlineHighlight={true}
      control={control}
      defaultValue={defaultValue}
      errorRef={errorRef}
      validationFailure={validationFailure}
      type={showPassword ? "text" : "password"}
      help={help}
      className={className}
      errorClassName={errorClassName}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowPassword(!showPassword)}
            onMouseDown={(e) => e.preventDefault()}
            edge="end"
            data-testid={"passwordInputVisibilityIcon"}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};
