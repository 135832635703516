import { makeStyles } from "@mui/styles";
import React, { useEffect, PropsWithChildren } from "react";

const useStyles = () =>
  makeStyles(() => ({
    scrollLocationReference: {
      position: "absolute",
      visibility: "hidden",
    },
  }))();

function isElementVisible(el: HTMLDivElement) {
  const rect = el.getBoundingClientRect(),
    vWidth = window.innerWidth || document.documentElement.clientWidth,
    vHeight = window.innerHeight || document.documentElement.clientHeight,
    efp = function (x: number, y: number) {
      return document.elementFromPoint(x, y);
    };

  // Return false if it's not in the viewport
  if (
    rect.right < 0 ||
    rect.bottom < 0 ||
    rect.left > vWidth ||
    rect.top > vHeight
  )
    return false;

  // Return true if any of its four corners are visible
  return (
    el.contains(efp(rect.left, rect.top)) ||
    el.contains(efp(rect.right, rect.top)) ||
    el.contains(efp(rect.right, rect.bottom)) ||
    el.contains(efp(rect.left, rect.bottom))
  );
}

export type ScrollHereIfNotVisibleOnRenderProps = {
  children?: any;
  scrollOnInitialRenderOnly?: boolean;
};

export const ScrollHereIfNotVisibleOnRender: React.FC<ScrollHereIfNotVisibleOnRenderProps> =
  ({
    children,
    scrollOnInitialRenderOnly = true,
  }: PropsWithChildren<ScrollHereIfNotVisibleOnRenderProps>) => {
    const ref = React.createRef<HTMLDivElement>();
    const hasRenderedAlready = React.useRef<boolean>(false);
    const classes = useStyles();

    useEffect(() => {
      if (
        ref.current &&
        !isElementVisible(ref.current) &&
        !hasRenderedAlready.current
      ) {
        ref.current.scrollIntoView({ behavior: "smooth" });
        if (scrollOnInitialRenderOnly) {
          hasRenderedAlready.current = true;
        }
      }
    });

    return (
      <>
        <div className={classes.scrollLocationReference} ref={ref} />
        {children && children}
      </>
    );
  };
