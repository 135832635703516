import React, { PropsWithChildren, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import classnames from "classnames";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import { FHModalOrDrawer } from "../FHModalOrDrawer";
import { useIsMobile } from "../../../hooks/hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
  },
  checkboxRoot: {
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.text.primary,
    },
    "&:focus, &:active": {
      color: theme.palette.primary.main,
    },
  },
  checked: {
    color: theme.palette.primary.main,
    "&:hover, &:focus, &:active": {
      color: theme.palette.primary.main,
    },
  },
  disabled: {
    color: theme.palette.action.disabled,
    "&:hover, &:focus, &:active": {
      color: theme.palette.action.disabled,
    },
  },
  labelRoot: {
    fontFamily: theme.typography.h2.fontFamily,
    lineHeight: "21px",
    "&.Mui-disabled": {
      color: theme.palette.grey[500],
    },
  },
  checkboxGroup: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  details: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    color: theme.palette.text.primary,
    textDecoration: "underline",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(3),
    },
  },
  detailsText: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 2),
    },
    "& p": {
      marginBottom: theme.spacing(2),
    },
  },
}));

export type FHCheckboxFieldProps = {
  name: string;
  label?: string;
  customOnChange?: (event: any) => void;
  validationFailure?: string;
  rules?: object;
  control?: any;
  errorRef?: any;
  defaultValue?: boolean;
  className?: string;
  errorClassName?: string;
  disabled?: boolean;
  detailsLabel?: string;
  detailsModalTitle?: string;
  detailsText?: JSX.Element;
  isTermsBoxClickable?: boolean;
  clickableTermsAndConditions?: boolean;
};

export const FHCheckboxField: React.FC<FHCheckboxFieldProps> = ({
  label,
  name,
  customOnChange = () => {},
  validationFailure,
  rules,
  control,
  errorRef = null,
  defaultValue,
  className,
  errorClassName,
  disabled,
  detailsLabel,
  detailsModalTitle,
  detailsText,
  isTermsBoxClickable,
  clickableTermsAndConditions,
}: PropsWithChildren<FHCheckboxFieldProps>) => {
  const isMobile = useIsMobile();
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Controller
        {...{ control, rules, name, defaultValue }}
        render={({ field: { onChange, value, name, ref } }) => (
          <div className={classes.checkboxGroup}>
            <FormControlLabel
              inputRef={ref}
              className={classnames(classes.root, className)}
              classes={{
                label: classes.labelRoot,
              }}
              label={label}
              control={
                <Checkbox
                  color="default"
                  icon={<CheckBoxOutlineBlankOutlinedIcon />}
                  checkedIcon={
                    disabled ? (
                      <CheckBoxTwoToneIcon />
                    ) : (
                      <CheckBoxOutlinedIcon />
                    )
                  }
                  classes={{
                    root: classes.checkboxRoot,
                    checked: classes.checked,
                    disabled: classes.disabled,
                  }}
                  disabled={disabled}
                  onChange={({ target }) => {
                    onChange(target.checked);
                    customOnChange && customOnChange(target.checked);
                  }}
                  {...{
                    name,
                    checked: clickableTermsAndConditions
                      ? isTermsBoxClickable
                      : value,
                  }}
                />
              }
            />
            {detailsLabel && (
              <Typography
                className={classes.details}
                onClick={() => setModalOpen(true)}
              >
                {detailsLabel}
              </Typography>
            )}
          </div>
        )}
      />
      {errorRef && (
        <div className={errorClassName}>
          {validationFailure && (
            <Typography className={errorClassName}>
              {validationFailure}
            </Typography>
          )}
        </div>
      )}
      <FHModalOrDrawer
        isMobile={isMobile}
        title={detailsModalTitle}
        openModal={modalOpen}
        onCloseClick={() => setModalOpen(false)}
      >
        <div className={classes.detailsText}>{detailsText}</div>
      </FHModalOrDrawer>
    </>
  );
};
export default FHCheckboxField;
